export interface TableAction {
  id: Action;
  name: string;
}

export enum Action {
  EDIT,
  VIEW,
  DELETE,
  CANCEL,
  MAIL,
  NEWORDER,
  REORDER,
  CONFIRMORDER,
  REJECTORDER,
  USEROFF,
  CONFIRMCUSTOMER,
}
