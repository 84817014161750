import { CartProduct } from "@/models/entities/cart-product.interface";
import { OrderAdd } from "@/models/entities/order-add.interface";
import { OrderProduct } from "@/models/entities/order-product.interface";
import { OrderRemove } from "@/models/entities/order-remove.interface";
import { Order } from "@/models/entities/order.interface";
import { OrderStatus } from "@/models/utils/order-status.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "order";

class OrdersService extends ApiService {
  getAllOrders(fromDate?: Date, toDate?: Date, status?: OrderStatus) {
    return this.getAll<Order[]>(CONTROLLER, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        status: status,
      },
    });
  }
  getAllOrdersCurrentClient(
    fromDate?: Date,
    toDate?: Date,
    status?: OrderStatus
  ) {
    return this.getAll<Order[]>(`${CONTROLLER}/personal`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        status: status,
      },
    });
  }
  getPDF(fromDate?: Date, toDate?: Date) {
    return this.getAll<string[]>(`${CONTROLLER}/pdf`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
  }
  getOrderByID(id: number) {
    return this.getByID<number, Order>(CONTROLLER, id);
  }
  getOrderProductsByID(id: number) {
    return this.getAll<OrderProduct[]>(`${CONTROLLER}/${id}/products`);
  }
  getOrderProductsHistoryByOrderID(id: number) {
    return this.getAll<OrderProduct[]>(`${CONTROLLER}/${id}/products/history`);
  }
  getCart() {
    return this.getAll<CartProduct[]>(`${CONTROLLER}/cart`);
  }
  sendCart(toDeliver: boolean) {
    return this.post<boolean, number>(CONTROLLER, toDeliver);
  }
  getDelivery() {
    return this.getAll<boolean>(`${CONTROLLER}/delivery`);
  }
  emptyCart() {
    return this.post<void, number>(`${CONTROLLER}/empty`, null);
  }
  addOrder(order: OrderAdd) {
    return this.post<OrderAdd, number>(`${CONTROLLER}/cart/add`, order);
  }
  removeOrder(order: OrderRemove) {
    return this.post<OrderRemove, number>(`${CONTROLLER}/cart/remove`, order);
  }
  confirmOrder(id: number) {
    return this.update<number, void>(`${CONTROLLER}/${id}/confirm`, null);
  }
  rejectOrder(id: number) {
    return this.update<number, void>(`${CONTROLLER}/${id}/cancel`, null);
  }
  sendConfirmationEmail(id: number) {
    return this.getAll<string>(`${CONTROLLER}/${id}/send-email`);
  }
  saveCustomerCart(id: number, order: CartProduct[]) {
    return this.post<CartProduct[], number>(`${CONTROLLER}/${id}`, order);
  }
}

export const orders = new OrdersService();
