
import { Customer } from "@/models/entities/customer.interface";
import { Component, Prop, Vue, PropSync } from "vue-property-decorator";
import {
  required,
  email,
  vatNumber,
  fiscalCode,
  province,
  postalCode,
} from "@/helpers/rules";
import { CustomerType } from "@/models/entities/customer-type.interface";
import { cities } from "@/services/api/cities.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { City } from "@/models/entities/city.interface";
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class CustomerForm extends Vue {
  /** Sets the form value */
  @Prop({ required: true }) private customer: Customer;
  /** Sets if the form is edit */
  @Prop({ required: true }) private isEdit: boolean;
  /** Sets the customer types */
  @Prop({ required: true }) private customerTypes: CustomerType[];
  /** Sets the selected customer type */
  @PropSync("customerTypeId") private selectedCustomerTypeId: number | null;

  private cityLoading = false;
  private postalCodesLoading = false;
  private cities = [] as City[];
  private postalCodes = [] as string[];
  private required = required;
  private email = email;
  private vatNumber = vatNumber;
  private fiscalCode = fiscalCode;
  private province = province;
  private postalCode = postalCode;

  mounted(): void {
    this.$emit("validate");
  }

  async created(): Promise<void> {
    this.cityLoading = true;
    await this.getAllCities();
  }

  private async getAllCities(): Promise<void> {
    try {
      const c = await cities.getAllCities(false);
      this.cities = c.data;
      if (this.isEdit) {
        this.$set(this.customer, "city", this.customer.city.toUpperCase());
        this.filterProvince(this.isEdit);
      }
    } catch {
      snackbarModule.showSnackbar({
        message: "Errore durante il caricamento delle città",
        type: "error",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
    this.cityLoading = false;
  }

  private filterProvince(isEditAndIsCreated: boolean): void {
    let city = {} as City;
    this.postalCodes = [] as string[];
    if (!isEditAndIsCreated) this.$set(this.customer, "postalCode", "");
    this.$set(this.customer, "province", "");
    city = this.cities.find((city) => {
      if (city.name == this.customer.city) {
        return city;
      }
    });
    if (city) {
      this.$set(this.customer, "province", city.provinceCode);
      this.postalCodesLoading = true;
      this.getCapsByCityId(city.id);
    }
  }

  private async getCapsByCityId(id: number): Promise<void> {
    const cap = await cities.getCapListById(id);
    this.postalCodes = cap.data;
    this.postalCodesLoading = false;
  }

  isPrivate(id: number): boolean {
    let c = this.customerTypes.find((el) => el.id == id);
    if (c) {
      return c.isPrivate;
    }
    return null;
  }

  private uppercase(): void {
    this.customer.fiscalCode = this.customer.fiscalCode.toUpperCase();
  }
}
