export const required = (v: string): string | boolean =>
  !!v || "Campo obbligatorio";

export const province = (v: string): string | boolean =>
  !v || /^[A-Z]{2}$/i.test(v) || "Provincia non valida";

export const postalCode = (v: string): string | boolean =>
  !v || /^[0-9]{5}$/i.test(v) || "CAP non valido";

export const vatNumber = (v: string): string | boolean =>
  !v || /^[0-9]{11}$/i.test(v) || "P.IVA non valida";

export const email = (v: string): string | boolean =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email non valida";

export const fiscalCode = (v: string): string | boolean =>
  /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i.test(
    v
  ) || "Codice fiscale non valido";

export const phone = (v: string): string | boolean =>
  !v ||
  /^\+?\s?\d{1,4}\s?\(?\d{1,4}\)?\s?\d{1,8}$/.test(v) ||
  "Numero di telefono non valido";

export const passwordLength = (psw: string): string | boolean =>
  psw == null || psw.length >= 8 || "Minimo 8 caratteri";

export const passwordCheck = (psw1: string, psw2: string): string | boolean =>
  psw1 == psw2 || "Password differenti";

export const passwordIsPassword = (psw: string): string | boolean =>
  psw != "password" || "Password non sicura";

export const checkEmailExistance = (emailCheck: boolean): string | boolean =>
  emailCheck || "Email già utilizzata";

export const checkEmailInDB = (emailCheck: boolean): string | boolean =>
  emailCheck == false || "Utente non trovato";
