import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import SnackbarModule from "@/store/modules/snackbar.module";
import OverlayModule from "@/store/modules/overlay.module";
import qs from "qs";
const authModule = getModule(AuthModule, store);
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

axios.interceptors.request.use(
  async (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    overlayModule.hideOverlay();
    return response;
  },
  function (error) {
    if (!axios.isCancel(error)) {
      if (error.response && error.response.status) {
        overlayModule.resetOverlay();
        switch (error.response.status) {
          case 404:
            snackbarModule.showSnackbar({
              message:
                "Errore " + error.response.status + ": elemento non trovato",
              type: "error",
            });
            setTimeout(() => snackbarModule.hideSnackbar(), 5000);
            break;
          case 403:
            snackbarModule.showSnackbar({
              message: "Errore " + error.response.status + ": Permesso negato",
              type: "error",
            });
            setTimeout(() => snackbarModule.hideSnackbar(), 5000);
            break;
          case 401:
            if (router.currentRoute.path !== "/login") {
              authModule.logout();
              router.push({ name: "login" });
            } else {
              authModule.loginError(error.response.data);
            }
            break;
          case 400:
            if (router.currentRoute.path !== "/login") {
              if (
                error.response.data.path !== "/api/order/cart/add" &&
                router.currentRoute.path !== "/my-orders"
              ) {
                /* Bad Request */
                snackbarModule.showSnackbar({
                  message:
                    "Errore " +
                    error.response.status +
                    ": " +
                    error.response.data.error,
                  type: "error",
                });
                setTimeout(() => snackbarModule.hideSnackbar(), 5000);
              }
            }
            break;
          default:
            snackbarModule.showSnackbar({
              message:
                "Errore " +
                error.response.status +
                ": " +
                error.response.data.error,
              type: "error",
            });
            setTimeout(() => snackbarModule.hideSnackbar(), 5000);
        }
      } else {
        if (error.message) {
          snackbarModule.showSnackbar({
            message: "Si è verificato un errore: " + error.message,
            type: "error",
          });
          setTimeout(() => snackbarModule.hideSnackbar(), 5000);
        } else {
          snackbarModule.showSnackbar({
            message: "Si è verificato un errore.",
            type: "error",
          });
          setTimeout(() => snackbarModule.hideSnackbar(), 5000);
        }
      }
    }

    return Promise.reject(error);
  }
);

export abstract class ApiService {
  baseUrl: string;
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_STANDARD;
  }

  urlencodedForm<ResponseType>(
    uri: string,
    params: string
  ): Promise<AxiosResponse<ResponseType>> {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return axios.post<string, AxiosResponse<ResponseType>>(
      this.baseUrl + uri,
      params,
      config
    );
  }

  getAll<ResponseType>(
    uri: string,
    params: AxiosRequestConfig = null,
    dateFormat = "yyyy-MM-dd"
  ): Promise<AxiosResponse<ResponseType>> {
    if (params) {
      params.paramsSerializer = (params) => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
          serializeDate: (date: Date) => date.toFormat(dateFormat),
        });
      };
    }

    return axios.get<ResponseType>(this.baseUrl + uri, params);
  }

  getByID<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType,
    params: AxiosRequestConfig = null,
    dateFormat = "yyyy-MM-dd"
  ): Promise<AxiosResponse<ResponseType>> {
    if (params) {
      params.paramsSerializer = (params) => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
          serializeDate: (date: Date) => date.toFormat(dateFormat),
        });
      };
    }

    return axios.get<ResponseType>(`${this.baseUrl}${uri}/${id}`, params);
  }

  post<BodyType, ResponseType>(
    uri: string,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.post<BodyType, AxiosResponse<ResponseType>>(
      `${this.baseUrl}${uri}`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  delete<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.delete(`${this.baseUrl}${uri}/${id}`);
  }

  deleteAlternative<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType,
    extraUri: string
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.delete(`${this.baseUrl}${uri}/${id}/${extraUri}`);
  }

  upload<ResponseType>(
    uri: string,
    formdata: FormData
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.post(`${this.baseUrl}${uri}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  uploadById<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType,
    formdata: FormData
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.put(`${this.baseUrl}${uri}/${id}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  patch<BodyType, ResponseType>(
    uri: string,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.patch(`${this.baseUrl}${uri}`, params);
  }

  update<BodyType, ResponseType>(
    uri: string,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.put(`${this.baseUrl}${uri}`, params);
  }

  updateById<IdentifierType, BodyType, ResponseType>(
    uri: string,
    id: IdentifierType,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.put(`${this.baseUrl}${uri}/${id}`, params);
  }
}
