
import { Component, Vue } from "vue-property-decorator";
import { customers } from "@/services/api/customers.service";
import { customerTypes } from "@/services/api/customer-type.service";
import { products } from "@/services/api/products.service";
import { Customer } from "@/models/entities/customer.interface";
import { CustomerType } from "@/models/entities/customer-type.interface";
import { ProductsCard } from "@/models/entities/products-card.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { DataTable } from "@/models/utils/datatable.interface";
import { Action } from "@/models/utils/table-action.interface";
import CustomerForm from "./CustomerForm.vue";
import CustomerNewOrder from "./CustomerNewOrder.vue";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import CartModule from "@/store/modules/cart.module";
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);
const cartModule = getModule(CartModule, store);

@Component({ components: { CustomerForm, CustomerNewOrder } })
export default class Customers extends Vue {
  private dialogDelete = false;
  private customerToDelete: number = null;
  private onDeleteMessage = "";
  private onDeleteSpecialMessage = "";
  private areItemsConnected = false;
  private customerDialog = {
    show: false,
    title: "",
    icon: "",
    customer: {} as Customer,
    customerTypes: [] as CustomerType[],
    selectedCustomerTypeId: null,
    idEdit: false,
  };
  private newOrderDialog = {
    show: false,
    title: "",
    icon: "",
    customer: {} as Customer,
    products: [] as ProductsCard[],
  };
  private newOrderSearch = "";
  private valid = false;
  private search = "";
  private customerTypes: CustomerType[] = [];
  private customerTypesWithAll: CustomerType[] = [];
  private selectedCustomerType = null;
  private isConfirmedFilter = false;

  private allHeader: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Nome",
      align: "start",
      filterable: true,
      value: "fullName",
      cellType: CellType.CUSTOMERNAME,
      isPrimary: true,
      customProp: "businessName",
      class: "llDataTable_header black--text",
    },
    {
      text: "Codice fiscale",
      align: "start",
      filterable: true,
      value: "fiscalCode",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "P.IVA",
      align: "start",
      filterable: true,
      value: "vatNumber",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Indirizzo",
      align: "start",
      filterable: true,
      value: "fullAddress",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Email",
      align: "start",
      filterable: true,
      value: "email",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Telefono",
      align: "start",
      filterable: true,
      value: "phone",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Gestito manualmente",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isManuallyManaged",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Confermato",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isConfirmed",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];
  private privateHeader: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Nome",
      align: "start",
      filterable: true,
      value: "fullName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Codice fiscale",
      align: "start",
      filterable: true,
      value: "fiscalCode",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Indirizzo",
      align: "start",
      filterable: true,
      value: "fullAddress",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Email",
      align: "start",
      filterable: true,
      value: "email",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Telefono",
      align: "start",
      filterable: true,
      value: "phone",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Gestito manualmente",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isManuallyManaged",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];
  private companyHeader: Header[] = [
    {
      text: "ID",
      align: "start",
      filterable: true,
      value: "id",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Ragione sociale",
      align: "start",
      filterable: true,
      value: "businessName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "P.IVA",
      align: "start",
      filterable: true,
      value: "vatNumber",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Indirizzo",
      align: "start",
      filterable: true,
      value: "fullAddress",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Email",
      align: "start",
      filterable: true,
      value: "email",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Telefono",
      align: "start",
      filterable: true,
      value: "phone",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Gestito manualmente",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isManuallyManaged",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Confermato",
      align: "start",
      filterable: false,
      sortable: false,
      value: "isConfirmed",
      cellType: CellType.BOOLEAN,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Azioni",
      align: "start",
      filterable: false,
      sortable: false,
      value: "actions",
      cellType: CellType.ACTION,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];

  private headers: Header[] = this.allHeader;

  public items: DataTable<unknown> = {
    key: "id",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [
      { id: Action.EDIT, name: "edit" },
      { id: Action.NEWORDER, name: "new-order" },
      { id: Action.DELETE, name: "delete" },
      { id: Action.CONFIRMCUSTOMER, name: "confirm-customer" },
    ],
  };

  async created(): Promise<void> {
    await this.getCustomerTypes();
  }

  private async getCustomerTypes(): Promise<void> {
    overlayModule.showOverlay();
    let r = await customerTypes.getAllCustomerType();
    this.customerTypes = r.data;
    this.customerTypesWithAll = Object.assign([], r.data);
    this.customerTypesWithAll.unshift({
      id: -1,
      customerTypeName: "Tutti",
      isPrivate: false,
    });
    this.selectedCustomerType = this.customerTypesWithAll[0];
    this.setTableHeader(this.selectedCustomerType);
  }

  private async setTableHeader(
    selectedCustomerType: CustomerType
  ): Promise<void> {
    if (selectedCustomerType.customerTypeName == "Tutti") {
      this.$set(this.items, "headers", this.allHeader);
    } else if (selectedCustomerType.isPrivate) {
      this.$set(this.items, "headers", this.privateHeader);
    } else {
      this.$set(this.items, "headers", this.companyHeader);
    }
    await this.getTableItems(
      selectedCustomerType.id,
      this.isConfirmedFilter ? false : null
    );
  }

  async getTableItems(
    customerTypeID: number,
    isConfirmed?: boolean
  ): Promise<void> {
    overlayModule.showOverlay();
    if (customerTypeID == -1) {
      customerTypeID = null;
    }
    const r = await customers.getAllCustomersByCustomerTypeID(
      customerTypeID,
      isConfirmed
    );
    this.items.loading = false;
    this.$set(this.items, "values", r.data);
  }

  private onSearch(value: string): void {
    this.$set(this.items, "search", value);
  }

  private onChange(id: number): void {
    const selectedCustomer = this.customerTypesWithAll.find(
      (el) => el.id == id
    );
    if (selectedCustomer) {
      if (selectedCustomer.id != this.selectedCustomerType.id) {
        this.selectedCustomerType = selectedCustomer;
        this.setTableHeader(this.selectedCustomerType);
      }
    } else {
      snackbarModule.showSnackbar({
        message: "Tipologia cliente non trovata",
        type: "error",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private async showCustomerDialog(item: Customer): Promise<void> {
    this.$set(this.customerDialog, "show", true);
    this.$set(this.customerDialog, "icon", "mdi-account-tie");
    if (item) {
      this.$set(this.customerDialog, "title", "Modifica cliente");
      overlayModule.showOverlay();
      const r = await customers.getCustomerByID(item.id);
      this.$set(this.customerDialog, "customer", r.data);
      this.$set(
        this.customerDialog,
        "selectedCustomerTypeId",
        item.customerTypeID
      );
      this.$set(this.customerDialog, "isEdit", true);
      const ct = this.customerTypes.find((c) => c.id == item.customerTypeID);
      this.$set(
        this.customerDialog,
        "customerTypes",
        this.customerTypes.filter((el) => el.isPrivate == ct.isPrivate)
      );
    } else {
      this.$set(this.customerDialog, "title", "Nuovo cliente");
      this.$set(this.customerDialog, "customer", {} as Customer);
      this.$set(this.customerDialog, "selectedCustomerTypeId", null);
      this.$set(this.customerDialog, "isEdit", false);
      this.$set(this.customerDialog, "customerTypes", this.customerTypes);
    }
  }

  private closeCustomerDialog(): void {
    this.$set(this.customerDialog, "show", false);
  }

  private async confirmCustomer(customer: Customer): Promise<void> {
    overlayModule.showOverlay();
    const response = await customers.confirm(customer.id);
    if (response.status == 200) {
      snackbarModule.showSnackbar({
        message: "Utente confermato con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
      this.getTableItems(this.selectedCustomerType.id);
    }
  }

  private onIsConfirmedChange(): void {
    if (this.isConfirmedFilter == true) {
      this.getTableItems(this.selectedCustomerType.id, false);
    } else {
      this.getTableItems(this.selectedCustomerType.id);
    }
  }

  private async saveCustomer(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      this.customerDialog.customer.customerTypeID =
        this.customerDialog.selectedCustomerTypeId;
      if (this.customerDialog.customer.customerTypeID == 1) {
        delete this.customerDialog.customer.businessName;
        delete this.customerDialog.customer.vatNumber;
      }
      if (this.customerDialog.customer.customerTypeID == 2) {
        delete this.customerDialog.customer.firstName;
        delete this.customerDialog.customer.lastName;
        delete this.customerDialog.customer.fiscalCode;
      }
      if (this.customerDialog.customer.id) {
        overlayModule.showOverlay();
        await customers.updateCustomer(
          this.customerDialog.customer.id,
          this.customerDialog.customer
        );
      } else {
        overlayModule.showOverlay();
        await customers.saveCustomer(this.customerDialog.customer);
      }
      await this.getTableItems(this.selectedCustomerType.id);
      this.$set(this.customerDialog, "show", false);
      snackbarModule.showSnackbar({
        message: "Salvataggio avvenuto con successo",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private async showNewOrderDialog(item: Customer): Promise<void> {
    cartModule.empty();
    this.$set(this.newOrderDialog, "show", true);
    this.$set(this.newOrderDialog, "icon", "mdi-cart-plus");
    this.$set(this.newOrderDialog, "title", "Nuovo ordine");
    await Promise.all([this.getCustomerByID(item.id)]);
    await this.getOrderDialogProducts();
  }

  private async getCustomerByID(id: number): Promise<void> {
    overlayModule.showOverlay();
    const c = await customers.getCustomerByID(id);
    this.$set(this.newOrderDialog, "customer", c.data);
  }

  private async getOrderDialogProducts(): Promise<void> {
    overlayModule.showOverlay();
    const p = await products.getProductsPreview(
      this.newOrderDialog.customer.customerTypeID,
      this.newOrderSearch
    );
    this.$set(this.newOrderDialog, "products", p.data);
  }

  private closeNewOrderDialog(): void {
    this.$set(this.newOrderDialog, "show", false);
    cartModule.empty();
  }

  private async onDelete(item: Customer): Promise<void> {
    this.customerToDelete = item.id;
    const ct = this.customerTypes.find((c) => c.id == item.customerTypeID);
    if (ct.isPrivate) {
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare il cliente " +
        item.firstName.toUpperCase() +
        " " +
        item.lastName.toUpperCase() +
        " (id: " +
        item.id +
        ") ?";
      const tmp = (await customers.onDeleteCustomer(this.customerToDelete))
        .data as unknown[];
      if (tmp.length != 0) {
        this.onDeleteSpecialMessage =
          "Attenzione: eliminare prima tutti gli utenti associati";
        this.areItemsConnected = true;
      } else {
        this.onDeleteSpecialMessage = "";
        this.areItemsConnected = false;
      }
    } else {
      this.onDeleteMessage =
        "Sei sicuro di volere eliminare il cliente " +
        item.businessName.toUpperCase() +
        " ?";
      const tmp = (await customers.onDeleteCustomer(this.customerToDelete))
        .data as unknown[];
      if (tmp.length != 0) {
        this.onDeleteSpecialMessage =
          "Attenzione: eliminare prima tutti gli utenti associati";
        this.areItemsConnected = true;
      } else {
        this.onDeleteSpecialMessage = "";
        this.areItemsConnected = false;
      }
    }
    this.dialogDelete = true;
  }

  private async deleteCustomer(): Promise<void> {
    overlayModule.showOverlay();
    await customers.deleteCustomer(this.customerToDelete);
    this.setTableHeader(this.selectedCustomerType);
    this.dialogDelete = false;
    snackbarModule.showSnackbar({
      message: "Eliminazione avvenuta con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private onValidate(): void {
    this.$nextTick(() => {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }
}
