
import { Customer } from "@/models/entities/customer.interface";
import { ProductsCard } from "@/models/entities/products-card.interface";
import { CartProduct } from "@/models/entities/cart-product.interface";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { orders } from "@/services/api/orders.service";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import CancelTokenModule from "@/store/modules/cancelToken.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const cartModule = getModule(CartModule, store);
const cancelTokenModule = getModule(CancelTokenModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class CustomerNewOrder extends Vue {
  /** Sets the customer value */
  @Prop({ required: true }) private customer: Customer;
  /** Sets the customer value */
  @Prop({ required: true }) private products: ProductsCard[];
  @PropSync("search")
  private _search: string;

  get name(): string {
    if (this.customer.businessName) {
      return this.customer.businessName;
    }
    return this.customer.firstName + " " + this.customer.lastName;
  }

  get cartProducts(): CartProduct[] {
    return cartModule.products;
  }

  get totals(): unknown {
    return cartModule.totals;
  }

  private getOrderTotal(items: CartProduct[]): number {
    return items.reduce((a, b) => {
      return a + (b.isInPromo ? b.totalInPromo : b.total);
    }, 0);
  }

  private removeProduct(product: CartProduct): void {
    cartModule.delete(product);
  }

  private async confirmOrder(): Promise<void> {
    overlayModule.showOverlay();
    cancelTokenModule.empty();
    await orders.saveCustomerCart(this.customer.id, this.cartProducts);
    snackbarModule.showSnackbar({
      message: "Ordine salvato con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    this.$emit("confirmOrder");
  }
}
