import { CustomerType } from "@/models/entities/customer-type.interface";
import { CascadeDelete } from "@/models/utils/cascade-delete.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "customertype";

class CustomerTypeService extends ApiService {
  getAllCustomerType() {
    return this.getAll<CustomerType[]>(CONTROLLER);
  }
  getBeforeDelete(id: number) {
    return this.getAll<CascadeDelete[]>(`${CONTROLLER}/${id}/ondelete`);
  }
  saveCustomerType(customer: CustomerType) {
    return this.post<CustomerType, number>(CONTROLLER, customer);
  }
  onDeleteCustomerType(id: number) {
    return this.getAll<unknown>(`${CONTROLLER}/${id}/ondelete`);
  }
  deleteCustomerType(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const customerTypes = new CustomerTypeService();
