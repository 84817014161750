import { Product } from "@/models/entities/product.interface";
import { OnSaveProduct } from "@/models/entities/on-save-product.interface";
import { Tag } from "@/models/entities/tag.interface";
import { ApiService } from "../api.service";
import { ProductsCard } from "@/models/entities/products-card.interface";
import { helpers } from "@/helpers/methods";

const CONTROLLER = "product";

class ProductsService extends ApiService {
  getAllProducts() {
    return this.getAll<Product[]>(CONTROLLER);
  }
  getProductByID(id: number) {
    return this.getByID<number, Product>(CONTROLLER, id);
  }
  getTagsByProductID(id: number) {
    return this.getAll<Tag[]>(`${CONTROLLER}/${id}/tags`);
  }
  getProductsPreview(customerTypeId: number, search: string) {
    return this.getByID<number, ProductsCard[]>(
      `${CONTROLLER}/preview`,
      customerTypeId,
      {
        params: {
          search: search,
        },
      }
    );
  }
  getProductsView(search: string, categoryID: number) {
    return this.getAll<ProductsCard[]>(`${CONTROLLER}/view`, {
      params: {
        categoryID: categoryID != -1 ? categoryID : null,
        search: search,
      },
    });
  }
  getFeaturedProducts() {
    return this.getAll<ProductsCard[]>(`${CONTROLLER}/featured`);
  }
  getProductsFavorite(categoryID?: number, search?: string) {
    return this.getAll<ProductsCard[]>(`${CONTROLLER}/favorite`, {
      params: {
        categoryID: categoryID,
        search: search,
      },
    });
  }
  saveProduct(product: OnSaveProduct, files: File[], defaultFile: File) {
    product.files = files;
    product.defaultFile = defaultFile;
    return this.upload<void>(CONTROLLER, helpers.object.toFormData(product));
  }

  toggleIsFeatured(id: number) {
    return this.uploadById<number, void>(
      `${CONTROLLER}/toggleisfeatured`,
      id,
      null
    );
  }

  updateProduct(id: number, product: OnSaveProduct, files: File[], file: File) {
    product.files = files;
    product.defaultFile = file;
    return this.uploadById<number, void>(
      CONTROLLER,
      id,
      helpers.object.toFormData(product)
    );
  }
  deleteProduct(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const products = new ProductsService();
