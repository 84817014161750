import { Customer } from "@/models/entities/customer.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "customer";

class CustomersService extends ApiService {
  getAllCustomersByCustomerTypeID(
    customerTypeID?: number,
    isConfirmed?: boolean
  ) {
    return this.getAll<Customer[]>(CONTROLLER, {
      params: {
        customerTypeID: customerTypeID,
        isConfirmed: isConfirmed,
      },
    });
  }
  getAllManuallyManagedCustomers() {
    return this.getAll<Customer[]>(`${CONTROLLER}/manuallymanaged`);
  }
  getCustomerByID(id: number) {
    return this.getByID<number, Customer>(CONTROLLER, id);
  }
  getCustomerByVatNumber(vatNumber: string) {
    return this.getAll<string>(`${CONTROLLER}/byvat/${vatNumber}`);
  }
  saveCustomer(customer: Customer) {
    return this.post<Customer, number>(CONTROLLER, customer);
  }
  confirm(id: number) {
    return this.updateById<number, void, void>(
      `${CONTROLLER}/confirm`,
      id,
      null
    );
  }
  updateCustomer(id: number, customer: Customer) {
    return this.updateById<number, Customer, void>(CONTROLLER, id, customer);
  }
  onDeleteCustomer(id: number) {
    return this.getAll(`${CONTROLLER}/${id}/ondelete`, null);
  }
  deleteCustomer(id: number) {
    return this.delete<number, void>(CONTROLLER, id);
  }
}

export const customers = new CustomersService();
