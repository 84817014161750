import { ApiService } from "../api.service";
import { City } from "@/models/entities/city.interface";
import { CityUpdate } from "@/models/utils/city-update.interface";

const CONTROLLER = "cities";

class CitiesService extends ApiService {
  getAllUsedCities() {
    return this.getAll<City[]>(`${CONTROLLER}/delivery`);
  }
  getAllCities(filterExisting: boolean, toCheck?: boolean) {
    return this.getAll<City[]>(`${CONTROLLER}`, {
      params: {
        filterExisting: filterExisting,
        filterToCheck: toCheck,
      },
    });
  }
  getCapListById(id: number) {
    return this.getAll<string[]>(`${CONTROLLER}/${id}/postalCode`);
  }
  updateCity(cityUpdate: CityUpdate) {
    return this.post<CityUpdate, void>(`${CONTROLLER}/delivery`, cityUpdate);
  }
  deleteCity(id: number) {
    return this.delete<number, void>(`${CONTROLLER}/delivery`, id);
  }
}

export const cities = new CitiesService();
